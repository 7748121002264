<template>
  <div v-tippy :content="tooltip" :class="classes" @show="() => !!tooltip">
    <input
      type="checkbox"
      :checked="active"
      :indeterminate="loading"
      @click="toggle"
    />
    <label><slot /></label>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        toggle: true,
        checkbox: true,
        disabled: this.disabled,
        indeterminate: this.loading,
      }
    },
  },
  methods: {
    toggle() {
      if (this.active) {
        this.$emit('toggle-off')
      } else {
        this.$emit('toggle-on')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.disabled {
  pointer-events: none;
}
</style>
