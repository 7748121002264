<template>
  <grid-column ten>
    <h3>
      <i class="fas fa-user-circle icon" />
      Custom Fields
    </h3>
    <p class="section-description">
      Add custom information that is relevant to your organization
    </p>
    <list relaxed>
      <list-item>
        <list-content>
          <g-table basic unstackable single-line>
            <table-head>
              <table-row>
                <table-header>Name</table-header>
                <table-header>Type</table-header>
                <table-header />
              </table-row>
            </table-head>
            <table-body>
              <table-row>
                <table-cell>
                  <g-form id="new-aspect">
                    <form-field>
                      <form-input
                        name="Field Name"
                        :initial-value="newAspect.name"
                        placeholder="Add Custom Field"
                        @update="(value) => (newAspect.name = value)"
                      />
                    </form-field>
                  </g-form>
                </table-cell>
                <table-cell>
                  <dropdown
                    name="New Type"
                    selection
                    :initial-value="newAspect.type"
                    default-text="Select Type"
                    @update="(value) => (newAspect.type = value)"
                  >
                    <dropdown-item value="date"> Date </dropdown-item>
                    <dropdown-item value="text"> Text </dropdown-item>
                  </dropdown>
                </table-cell>
                <table-cell>
                  <btn
                    variant="primary"
                    :loading="aspectLoading"
                    @click.native="createAspect"
                  >
                    Add
                  </btn>
                </table-cell>
              </table-row>
              <aspect-setting
                v-for="aspect in aspects"
                :key="aspect.id"
                :aspect="aspect"
                @get-aspects="() => $emit('get-aspects')"
              />
            </table-body>
          </g-table>
        </list-content>
      </list-item>
      <list-item>
        <pagination
          v-if="pagination && pagination.lastPage > 1"
          :num-of-pages="pagination.lastPage"
        />
      </list-item>
    </list>
  </grid-column>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import gridColumn from '@/components/v2/grid/column.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import btn from '@/components/v2/btn.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import gTable from '@/components/v2/table/table.vue'
import tableHead from '@/components/v2/table/head.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableHeader from '@/components/v2/table/header.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import formInput from '@/components/v2/form/input.vue'
import formField from '@/components/v2/form/field.vue'
import gForm from '@/components/v2/form/form.vue'

import aspectSetting from '@/views/v2/settings/aspect_setting.vue'

export default {
  components: {
    list,
    listItem,
    listContent,
    btn,
    dropdown,
    dropdownItem,
    gTable,
    tableHead,
    tableCell,
    tableHeader,
    tableBody,
    tableRow,
    pagination,
    aspectSetting,
    gridColumn,
    formInput,
    formField,
    gForm,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    aspects: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      aspectLoading: false,
      patchingAspectField: null,
      newAspect: {
        name: '',
        type: 'text',
      },
    }
  },
  methods: {
    async createAspect() {
      try {
        this.aspectLoading = true
        await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`, {
          name: this.newAspect.name,
          type: this.newAspect.type,
          organizationId: this.organization.id,
        })
        this.newAspect = { name: '', type: 'text' }
      } catch (error) {
        toast.error(error)
      } finally {
        this.aspectLoading = false
      }
      this.$emit('get-aspects')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.list-section-header {
  margin-top: 1em;
}
</style>
