<template>
  <grid-column ten>
    <h3>
      <i class="fas fa-credit-card icon" />
      Billing
    </h3>
    <p class="portal-description">
      Update your information and view history in the Billing Portal
    </p>
    <btn
      :loading="billingLoading"
      basic
      variant="default"
      :disabled="!account.stripeCustomerId"
      @click.native="openStripeCustomerPortal"
    >
      Billing Portal
    </btn>
  </grid-column>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import gridColumn from '@/components/v2/grid/column.vue'
import btn from '@/components/v2/btn.vue'

export default {
  components: {
    gridColumn,
    btn,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      billingLoading: false,
    }
  },
  methods: {
    async openStripeCustomerPortal() {
      try {
        this.billingLoading = true
        const response = (
          await api.post(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/stripe/portal`,
            {
              stripeCustomerId: this.account.stripeCustomerId,
            }
          )
        ).data
        window.open(response.url, '_blank')
      } catch (error) {
        toast.error(error)
      } finally {
        this.billingLoading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.portal-description {
  margin: 1em 0 0.5em 0;
}

.list-section-header {
  margin-top: 1em;
}
</style>
