export default {
  methods: {
    /**
     * create slack connection url
     *
     * @param {object} state - holds additional options that can be passed to slack
     * @returns {string} - slack url
     */
    buildSlackConnectionUrl(state = {}) {
      return `https://slack.com/oauth/v2/authorize?client_id=${
        process.env.VUE_APP_SLACK_CLIENT_ID
      }&scope=channels:manage,channels:read,chat:write,chat:write.public,chat:write.customize,groups:read,groups:write,im:read,im:write,mpim:read,mpim:write,team:read,users:read,users:read.email,app_mentions:read,im:history&redirect_uri=${
        process.env.VUE_APP_SLACK_REDIRECT_URI
      }&state=${JSON.stringify(state)}`
    },
  },
}
