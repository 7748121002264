<template>
  <list middle-aligned>
    <list-item class="no-bottom-padding">
      <flex>
        <flex gap="0.5rem" align="center" class="date-picker">
          <date-picker
            ref="datePicker"
            dropdown
            variant="isoDate"
            :date="parseISO(newQuietDay)"
            :loading="quietDayLoading"
            :min-date="new Date()"
            @update="(value) => (newQuietDay = value)"
          />
          <btn compact :disabled="!newQuietDay" @click.native="addQuietDay">
            Add
          </btn>
        </flex>
      </flex>
    </list-item>
    <list-item
      v-for="quietDay in quietDays"
      :key="quietDay.id"
      class="list-date"
    >
      <flex justify="space-between" align="center">
        {{ formatQuietDay(quietDay.date) }}
        <btn
          dismiss
          :loading="deletingQuietDay === quietDay.id"
          tooltip="Remove day"
          compact
          size="mini"
          @click.native="() => removeQuietDay(quietDay.id)"
        />
      </flex>
    </list-item>
  </list>
</template>

<script>
import { parseISO } from 'date-fns'

import { api } from '@/api'
import { toast } from '@/toasts'

import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import datePicker from '@/components/v2/date_picker.vue'
import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'
import dateMixin from '@/mixins/v2/dateMixin'

export default {
  components: {
    list,
    listItem,
    datePicker,
    btn,
    flex,
  },
  mixins: [dateMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    quietDays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      parseISO,
      quietDayLoading: false,
      calendarValue: null,
      newQuietDay: null,
      showQuietDays: false,
      deletingQuietDay: null,
    }
  },
  methods: {
    async addQuietDay() {
      try {
        this.quietDayLoading = true
        await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/quiet-days`, {
          date: this.newQuietDay,
          organizationId: this.organization.id,
        })
        this.newQuietDay = null
      } catch (error) {
        toast.error(error)
      } finally {
        this.quietDayLoading = false
      }
      this.$emit('get-quiet-days')
    },
    async removeQuietDay(id) {
      try {
        this.deletingQuietDay = id
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/quiet-days/${id}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingQuietDay = null
      }
      this.$emit('get-quiet-days')
    },
    formatQuietDay(date) {
      return this.formatDate(parseISO(date), true)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.date-picker {
  margin-top: 0.4em;
  border-bottom: 1px lightgray solid;
  padding-bottom: 0.2em;
}

.list-date {
  margin-left: 2.65em;
  width: 13rem;
}
</style>
