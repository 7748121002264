<template>
  <container>
    <page-header>
      <grid>
        <grid-column eight>
          <h1>Settings</h1>
        </grid-column>
        <grid-column eight right-aligned>
          <btn @click.native="logout"> Log Out </btn>
        </grid-column>
      </grid>
    </page-header>
    <loading v-if="pageLoading" :loading="pageLoading" />
    <grid v-else stackable class="settings-grid">
      <organization-settings
        :organization="organization"
        :account="account"
        :quiet-days="quietDays"
        :quiet-days-pagination="quietDaysPagination"
        @get-organization="getOrganization"
        @get-quiet-days="getQuietDays"
      />

      <integration-settings
        :organization="organization"
        :slack-integration="slackIntegration"
        :finch-integration="finchIntegration"
        @get-organization="getOrganization"
        @get-slack-integration="getSlackIntegration"
        @get-finch-integration="getFinchIntegration"
      />

      <billing-settings :account="account" />

      <information-settings
        :aspects="aspects"
        :pagination="aspectsPagination"
        :organization="organization"
        @get-aspects="getAspects"
      />

      <admin-settings />
    </grid>
  </container>
</template>

<script>
import { formatISO } from 'date-fns'
import { api } from '@/api'
import { toast } from '@/toasts'

import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import btn from '@/components/v2/btn.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import pageHeader from '@/components/v2/page_header.vue'

import organizationSettings from '@/views/v2/settings/organization_settings.vue'
import integrationSettings from '@/views/v2/settings/integration_settings.vue'
import billingSettings from '@/views/v2/settings/billing_settings.vue'
import informationSettings from '@/views/v2/settings/information_settings.vue'
import adminSettings from '@/views/v2/settings/admin_settings.vue'

export default {
  components: {
    loading,
    container,
    btn,
    grid,
    gridColumn,
    pageHeader,
    organizationSettings,
    integrationSettings,
    billingSettings,
    informationSettings,
    adminSettings,
  },
  data() {
    return {
      pageLoading: false,
      account: {},
      organization: {},
      slackIntegration: null,
      aspects: [],
      aspectsPagination: null,
      finchIntegration: null,
      quietDays: [],
      quietDaysPagination: null,
    }
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      try {
        this.pageLoading = true
        await this.getOrganization()
        await this.getSlackIntegration()
        this.account = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/accounts/${this.organization.accountId}`
          )
        ).data
        await this.getAspects()
        await this.getQuietDays()

        if (this.organization.finchProviderName) {
          await this.getFinchIntegration()
        }
        if (this.$route.query.code && !this.organization.calendarOwnerId) {
          await this.getTokens()
        }
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async getAspects() {
      try {
        const { data, cursor } = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`,
          {
            sort: 'isEditable-desc,id',
            page: parseInt(this.$route.query.page || 1, 10),
            isEditable: 1,
          }
        )
        this.aspects = data
        this.aspectsPagination = cursor
      } catch (error) {
        toast.error(error)
      }
    },
    async getOrganization() {
      try {
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      }
    },
    async getSlackIntegration() {
      try {
        this.slackIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}/slack-integration`
          )
        ).data
      } catch (error) {
        if (error.code !== 404) {
          toast.error(error)
        }
        this.slackIntegration = null
      }
    },
    async getFinchIntegration() {
      try {
        this.finchIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/finch/info`
          )
        ).data
      } catch (error) {
        if (error.code !== 404) {
          toast.error(error)
        }
        this.finchIntegration = null
      }
    },
    async getQuietDays() {
      let response
      let quietDays = []
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/quiet-days`,
            {
              start: formatISO(new Date(), { representation: 'date' }),
              sort: 'date,id',
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
            }
          )
          quietDays = quietDays.concat(response.data)
          this.quietDaysPagination = response.cursor
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.quietDays = quietDays
    },
    async getTokens() {
      try {
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/g-cal/exchange`,
          { code: this.$route.query.code }
        )
      } catch (error) {
        toast.error(error)
      }
      await this.getOrganization()
    },
    logout() {
      this.$auth.logout({ returnTo: `${window.location.origin}/sign_in` })
    },
  },
}
</script>

<style lang="less" scoped>
.settings-grid {
  padding-bottom: 3em;
}
</style>
