<template>
  <grid-column eight>
    <h3>
      <i class="fas fa-plug icon" />
      Integrations
    </h3>
    <card fluid>
      <card-body @click.native="toggleSlackCard">
        <flex align="center" justify="center">
          <i
            v-if="isSlackCardToggleAllowed"
            :class="[
              'large grey icon fas fa-chevron-right pointer',
              { 'rotate-open': showSlackFold },
            ]"
          />
          <g-image
            :size="null"
            class="card-img"
            :src="slackLogo"
            alt="Slack logo"
          />
          <btn
            v-if="hasSlackIssue"
            class="flex-right pointer"
            basic
            variant="default"
            @click.native="openZendeskWidget"
          >
            <flex justify="space-between">
              ⚠️
              <div>Contact Gather</div>
            </flex>
          </btn>
          <btn
            v-else-if="slackIntegration && slackIntegration.needsUpdate"
            basic
            variant="default"
            class="flex-right"
            :loading="slackConnectLoading"
            @click.native="connectSlack"
          >
            ⚠️ Reconnect
          </btn>
          <div v-else-if="slackIntegration" class="flex-right">
            <i class="green large check circle icon" />
          </div>
          <btn
            v-else
            basic
            variant="default"
            class="flex-right"
            :loading="slackConnectLoading"
            @click.native="connectSlack"
          >
            Connect
          </btn>
        </flex>
        <p
          v-if="slackIntegration && slackIntegration.needsUpdate"
          class="issue-message"
        >
          There is an issue with your integration. Reconnect to resolve.
        </p>
      </card-body>
      <transition name="card-content">
        <card-body v-show="showSlackFold">
          <flex align="baseline">
            <div class="card-header">Last Synced:</div>
            {{ lastSlackSyncTime }}
          </flex>
          <flex v-if="slackIntegration" align="baseline">
            <div class="card-header">Custom Sender Name:</div>
            <editable-text
              :value="slackIntegration.displayName"
              :loading="patchingSlackIntegrationField === 'displayName'"
              placeholder="Add Custom Sender Name"
              @update="(value) => patchSlackIntegration('displayName', value)"
            />
          </flex>
          <flex
            v-if="slackIntegration && !slackIntegration.displayIcon"
            align="baseline"
          >
            <div class="card-header">Custom Sender Emoji:</div>
            <editable-text
              :value="slackIntegration.displayEmoji"
              :loading="patchingSlackIntegrationField === 'displayEmoji'"
              placeholder="Add Custom Sender Emoji"
              @update="(value) => patchSlackIntegration('displayEmoji', value)"
            />
            <i
              v-tippy
              class="fas fa-info-circle"
              content="Use Slack emoji format (ex. :heart:)"
            />
          </flex>
          <flex v-if="slackIntegration" align="flex-start">
            <div class="card-header">Custom Sender Image:</div>
            <btn
              v-if="!slackIntegration.displayIcon"
              basic
              variant="default"
              size="tiny"
              @click.native="openSlackCustomSenderHelpPage"
            >
              Follow Instructions
            </btn>
            <g-image
              v-if="slackIntegration.displayIcon"
              size="mini"
              alt="Custom Slack Sender Image"
              :src="slackIntegration.displayIcon"
            />
            <btn
              v-if="slackIntegration.displayIcon"
              dismiss
              tooltip="Remove Custom Sender Image"
              compact
              :loading="patchingSlackIntegrationField === 'displayIcon'"
              @click.native="$refs.removeSlackCustomSenderImageModal.show"
            />
          </flex>
          <div class="card-header">Import People:</div>
          <div>
            <toggle
              :active="!!(slackIntegration && slackIntegration.isSyncing)"
              :loading="patchingSlackIntegrationField === 'isSyncing'"
              @toggle-off="() => patchSlackIntegration('isSyncing', false)"
              @toggle-on="() => patchSlackIntegration('isSyncing', true)"
            >
              Automatically import new people
            </toggle>
          </div>

          <flex class="card-content-footer" justify="space-between">
            <btn
              variant="negative"
              inverted
              :loading="slackDisconnectLoading"
              @click.native="$refs.disconnectSlackModal.show"
            >
              Disconnect
            </btn>
            <btn :loading="slackSyncLoading" @click.native="syncSlack">
              Sync
            </btn>
          </flex>
        </card-body>
      </transition>
    </card>
    <card fluid>
      <card-body class="card" @click.native="toggleCalendarCard">
        <flex align="center" justify="center">
          <i
            v-if="organization.calendarOwnerId"
            :class="[
              'large grey icon fas fa-chevron-right pointer',
              { 'rotate-open': showCalendarFold },
            ]"
          />
          <g-image
            :size="null"
            class="card-img"
            :src="googleCalendarLogo"
            alt="Google Logo"
          />
          <btn
            v-if="hasCalendarIssue"
            class="flex-right pointer"
            basic
            variant="default"
            @click.native="openZendeskWidget"
          >
            <flex justify="space-between">
              ⚠️
              <div>Contact Gather</div>
            </flex>
          </btn>
          <div v-else-if="organization.calendarOwnerId" class="flex-right">
            <i class="green large check circle icon" />
          </div>
          <btn
            v-else
            basic
            variant="default"
            class="flex-right google-sign-in-button"
            @click.native="connectCalendar"
          >
            <g-image
              size="small"
              class="google-sign-in-logo"
              :src="googleSignInLogo"
              alt="Google Sign In Logo"
            />
          </btn>
        </flex>
      </card-body>
      <transition name="card-content">
        <card-body v-show="showCalendarFold">
          <flex align="baseline">
            <div class="card-header">Connected User:</div>
            {{ calendarOwner ? calendarOwner.fullName : null }}
          </flex>
          <flex class="card-content-footer">
            <btn
              variant="negative"
              inverted
              :loading="calendarDisconnectLoading"
              @click.native="$refs.disconnectGoogleCalendarModal.show"
            >
              Disconnect
            </btn>
          </flex>
        </card-body>
      </transition>
    </card>
    <card fluid>
      <card-body class="card" @click.native="toggleHrisCard">
        <flex v-if="finchIntegration" align="center" justify="center">
          <i
            v-if="isHrisCardToggleAllowed"
            :class="[
              'large grey icon fas fa-chevron-right',
              { 'rotate-open': showHrisFold },
            ]"
          />
          <g-image
            v-if="!hideHrisLogo"
            :size="null"
            class="card-img"
            :src="finchIntegration.logo"
            alt="HR Integration"
            @error="() => (hideHrisLogo = true)"
          />
          <div v-else>
            <h2>
              {{ finchIntegration.displayName }}
            </h2>
          </div>
          <btn
            v-if="hasHrisIssue"
            class="flex-right pointer"
            basic
            variant="default"
            @click.native="openZendeskWidget"
          >
            <flex justify="space-between" align="center">
              ⚠️
              <div>Contact Gather</div>
            </flex>
          </btn>
          <btn
            v-else-if="organization.finchNeedsAuth"
            basic
            variant="default"
            class="flex-right"
            :loading="hrisDisconnectLoading"
            @click.native="reconnectFinch"
          >
            ⚠️ Reconnect
          </btn>
          <div v-else-if="finchIntegration" class="flex-right">
            <i class="green large check circle icon" />
          </div>
        </flex>
        <flex v-else align="center" justify="center">
          <div>
            <h2>HR Platforms</h2>
          </div>
          <btn
            basic
            variant="default"
            class="flex-right"
            @click.native="$router.push({ name: 'people_import' })"
          >
            Connect
          </btn>
        </flex>
        <p v-if="organization.finchNeedsAuth" class="issue-message">
          There is an issue with your integration. Reconnect to resolve.
        </p>
      </card-body>
      <transition name="card-content">
        <card-body v-show="showHrisFold">
          <flex align="baseline">
            <div class="card-header">Last Synced:</div>
            {{ lastHrisSyncTime }}
          </flex>
          <div class="card-header">Import People:</div>
          <div>
            <toggle
              :active="organization.allowFinchDataRetrieval"
              :loading="patchingOrgField === 'allowFinchDataRetrieval'"
              @toggle-off="
                () => patchOrganization('allowFinchDataRetrieval', false)
              "
              @toggle-on="
                () => patchOrganization('allowFinchDataRetrieval', true)
              "
            >
              Automatically import new people
            </toggle>
          </div>

          <flex class="card-content-footer" justify="space-between">
            <btn
              variant="negative"
              inverted
              :loading="hrisDisconnectLoading"
              @click.native="$refs.disconnectHrisModal.show"
            >
              Disconnect
            </btn>
            <btn
              v-if="finchIntegration"
              :disabled="finchIntegration.isManual"
              :loading="hrisSyncLoading"
              @click.native="syncFinch"
            >
              Sync
            </btn>
          </flex>
        </card-body>
      </transition>
    </card>
    <confirm-modal
      ref="disconnectSlackModal"
      approve-text="Disconnect Slack"
      deny-text="Cancel"
      negative
      @approve="disconnectSlack"
    >
      Are you sure you want to disconnect your Slack integration? This will also
      clear the custom sender name, emoji and image.
    </confirm-modal>
    <confirm-modal
      ref="removeSlackCustomSenderImageModal"
      approve-text="Remove Image"
      deny-text="Cancel"
      negative
      @approve="() => patchSlackIntegration('displayIcon', null)"
    >
      Are you sure you want to remove your custom sender image?
    </confirm-modal>
    <confirm-modal
      ref="disconnectGoogleCalendarModal"
      approve-text="Disconnect Google Calendar"
      deny-text="Cancel"
      negative
      @approve="disconnectCalendar"
    >
      Are you sure you want to remove your Google Calendar integration?
    </confirm-modal>
    <confirm-modal
      ref="disconnectHrisModal"
      :approve-text="`Disconnect ${
        finchIntegration && finchIntegration.displayName
      }`"
      deny-text="Cancel"
      negative
      @approve="disconnectFinch"
    >
      Are you sure you want to disconnect your
      {{ finchIntegration && finchIntegration.displayName }} integration?
    </confirm-modal>
  </grid-column>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import slackMixin from '@/mixins/slackMixin'
import dateMixin from '@/mixins/v2/dateMixin'

import gridColumn from '@/components/v2/grid/column.vue'
import btn from '@/components/v2/btn.vue'
import gImage from '@/components/v2/image.vue'
import card from '@/components/v2/card/card.vue'
import cardBody from '@/components/v2/card/body.vue'
import flex from '@/components/v2/flex.vue'
import toggle from '@/components/v2/toggle.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import editableText from '@/components/v2/editable_text.vue'

import slackLogo from '@/assets/img/integrations/slack.png'
import googleCalendarLogo from '@/assets/img/integrations/googlecalendar.png'
import googleSignInLogo from '@/assets/img/integrations/googlesignin.png'

import customerCommunicationsMixin from '@/mixins/customerCommunicationsMixin'

export default {
  components: {
    gridColumn,
    btn,
    gImage,
    card,
    cardBody,
    flex,
    toggle,
    confirmModal,
    editableText,
  },
  mixins: [slackMixin, dateMixin, customerCommunicationsMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    slackIntegration: {
      type: Object,
      default: null,
    },
    finchIntegration: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      slackLogo,
      googleCalendarLogo,
      googleSignInLogo,
      hideHrisLogo: false,
      patchingSlackIntegrationField: null,
      patchingOrgField: false,
      showSlackFold: false,
      showHrisFold: false,
      showCalendarFold: false,
      hrisDisconnectLoading: false,
      slackDisconnectLoading: false,
      hasSlackIssue: false,
      hasHrisIssue: false,
      slackSyncLoading: false,
      slackConnectLoading: false,
      hrisSyncLoading: false,
      oAuthUrl: null,
      calendarDisconnectLoading: false,
      calendarOwner: null,
      calendarOwnerLoading: false,
      hasCalendarIssue: false,
    }
  },
  computed: {
    lastSlackSyncTime() {
      if (this.slackIntegration && this.slackIntegration.lastSlackSync) {
        return this.formatDatetime(
          new Date(this.slackIntegration.lastSlackSync)
        )
      }
      return 'Not Synced'
    },
    lastHrisSyncTime() {
      if (this.organization.lastFinchSync) {
        return this.formatDatetime(new Date(this.organization.lastFinchSync))
      }
      return 'Not Synced'
    },
    isSlackCardToggleAllowed() {
      return !!(
        this.slackIntegration &&
        !this.hasSlackIssue &&
        !this.slackIntegration.needsUpdate
      )
    },
    isHrisCardToggleAllowed() {
      return !!(
        this.finchIntegration &&
        !this.hasHrisIssue &&
        !this.organization.finchNeedsAuth
      )
    },
  },
  mounted() {
    this.hasSlackIssue = this.$route.query.error === 'slackFailed'
    this.hasHrisIssue = this.$route.query.error === 'hrisFailed'
    this.hasCalendarIssue = this.$route.query.error === 'access_denied'
  },
  methods: {
    connectSlack() {
      try {
        this.slackConnectLoading = true
        window.location.href = this.buildSlackConnectionUrl({
          name: this.$route.name,
          organizationId: this.organization.id,
          userId: this.$store.state.currentUser.id,
          redirectPath: '/settings',
          redirectErrorPath: '/settings?error=slackFailed',
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.slackConnectLoading = false
      }
    },
    async patchSlackIntegration(field, value) {
      try {
        this.patchingSlackIntegrationField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.organization.id}/slack-integration`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
        this.$forceUpdate()
      } finally {
        this.patchingSlackIntegrationField = null
      }
      this.$emit('get-slack-integration')
    },
    async syncFinch() {
      try {
        this.hrisSyncLoading = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/hris/sync-finch`,
          { organizationId: this.organization.id }
        )
      } catch (error) {
        toast.error(error)
        this.showHrisFold = false
        this.$forceUpdate()
      } finally {
        this.hrisSyncLoading = false
      }
      this.$emit('get-organization')
    },
    async patchOrganization(field, value) {
      try {
        this.patchingOrgField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.organization.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
        this.$forceUpdate()
      } finally {
        this.patchingOrgField = null
      }
      this.$emit('get-organization')
    },
    async disconnectFinch() {
      try {
        this.hrisDisconnectLoading = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/finch/disconnect`
        )
      } catch (error) {
        toast.error(error)
        this.$forceUpdate()
      } finally {
        this.hrisDisconnectLoading = false
      }
      this.$emit('get-finch-integration')
      this.showHrisFold = false
    },
    async disconnectSlack() {
      try {
        this.slackDisconnectLoading = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.organization.id}/slack-integration`
        )
      } catch (error) {
        toast.error(error)
        this.$forceUpdate()
      } finally {
        this.slackDisconnectLoading = false
      }

      this.$emit('get-slack-integration')
      this.showSlackFold = false
    },
    async syncSlack() {
      try {
        this.slackSyncLoading = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/slack/sync`
        )
      } catch (error) {
        toast.error(error)
        this.showSlackFold = false
        this.$forceUpdate()
      } finally {
        this.slackSyncLoading = false
      }
      this.$emit('get-slack-integration')
    },
    async reconnectFinch() {
      await this.disconnectFinch()
      this.$router.push({ name: 'people_import' })
    },
    async getOAuthUrl() {
      this.oAuthUrl = (
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/g-cal/auth`
        )
      ).data.url
    },
    async connectCalendar() {
      await this.getOAuthUrl()
      window.location.href = this.oAuthUrl
    },
    async getCalendarOwner() {
      if (
        this.organization.calendarOwnerId === this.$store.state.currentUser.id
      ) {
        this.calendarOwner = this.$store.state.currentUser
      } else {
        try {
          this.calendarOwnerLoading = true
          this.calendarOwner = (
            await api.get(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.organization.calendarOwnerId}`
            )
          ).data
        } catch (error) {
          toast.error(error)
        } finally {
          this.calendarOwnerLoading = false
        }
      }
    },
    async disconnectCalendar() {
      try {
        this.calendarDisconnectLoading = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/g-cal/revoke`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.calendarDisconnectLoading = false
      }
      this.showCalendarFold = false
      this.$emit('get-organization')
    },
    toggleSlackCard() {
      if (this.isSlackCardToggleAllowed) {
        this.showSlackFold = !this.showSlackFold
      } else {
        this.showSlackFold = false
      }
    },
    toggleHrisCard() {
      if (this.isHrisCardToggleAllowed) {
        this.showHrisFold = !this.showHrisFold
      } else {
        this.showHrisFold = false
      }
    },
    async toggleCalendarCard() {
      if (this.organization.calendarOwnerId) {
        await this.getCalendarOwner()
        this.showCalendarFold = !this.showCalendarFold
      } else {
        this.showCalendarFold = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/borders.less';

.card {
  padding: 0.5em 0;
}

.card-header {
  min-width: 12em;
  margin-bottom: 0.5em;
  font-weight: 700;
}

.card-img {
  height: 2em;
  margin-right: 1em;
  overflow: hidden;
}

.flex-right {
  margin-left: auto;
}

.google-sign-in-button {
  padding: 0;
}

.google-sign-in-logo {
  border-radius: @standard-border-radius;
  margin: 0.1em;
}

.rotate-open {
  transform: rotate(90deg);
}

.card-content-footer {
  margin-top: 1em;
}

.issue-message {
  padding-top: 1em;
}

.list-section-header {
  margin-top: 1em;
}

// transitions
.card-content-enter-active,
.card-content-leave-active {
  transition: all 0.3s ease-in-out;
}

.card-content-enter,
.card-content-leave-to {
  opacity: 0;
}
</style>
