<template>
  <grid-column ten>
    <h3>
      <i class="fas fa-key icon" />
      Admins
    </h3>
    <p class="admin-description">
      You can add new admins on each employee's profile page
    </p>
    <loading v-if="adminsLoading" loading />
    <list v-else middle-aligned divided selection>
      <list-item
        v-for="admin in admins"
        :key="admin.id"
        :src="admin.avatar || defaultUserAvatar"
        :to="{ name: 'profile', params: { id: admin.id } }"
      >
        <list-content>{{ admin.fullName }}</list-content>
      </list-item>

      <placeholder
        v-if="admins.length === 0 && !adminsLoading"
        vertically-aligned
        light
      >
        There are no admins
      </placeholder>
    </list>
  </grid-column>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import loading from '@/components/v2/loading.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import listContent from '@/components/v2/list/content.vue'
import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

export default {
  components: {
    gridColumn,
    list,
    listItem,
    listContent,
    placeholder,
    loading,
  },
  data() {
    return {
      defaultUserAvatar,
      adminsLoading: false,
      admins: [],
    }
  },
  mounted() {
    this.getAdmins()
  },
  methods: {
    async getAdmins() {
      try {
        this.adminsLoading = true
        this.admins = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/users`, {
            role: 'admin',
            sort: 'preferredFirstName,lastName,id',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.adminsLoading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.admin-description {
  margin: 1em 0 0.5em 0;
}
</style>
