<template>
  <grid-column ten>
    <h3>
      <i class="fas fa-building icon" />
      Organization Settings
    </h3>
    <p class="label-text">Name</p>
    <p class="org-name">
      {{ organization.name }}
      <g-label v-if="accountStatus" v-bind="{ [accountStatusColor]: true }">
        {{ accountStatus }}
      </g-label>
    </p>
    <p class="label-text">
      Timezone
      <i
        v-tippy
        class="fas fa-info-circle"
        content="Changing timezone may resend messages from today in rare cases"
      />
    </p>
    <dropdown
      name="Timezone"
      search
      selection
      :initial-value="organization.timezone"
      :loading="patchingOrgField === 'timezone'"
      default-text="Select Timezone"
      class="timezone-dropdown"
      @update="(value) => patchOrganization('timezone', value)"
    >
      <dropdown-item
        v-for="timezone in timezoneOptions"
        :key="timezone.name"
        :value="timezone.value"
      >
        {{ timezone.name }}
      </dropdown-item>
    </dropdown>

    <p class="label-text">
      Email Display Name
      <i
        v-tippy
        class="fas fa-info-circle"
        content='This is the name Gather will send emails as; it will be used as the email "From" field.'
      />
    </p>
    <editable-text
      :value="organization.emailFromName"
      :loading="patchingOrgField === 'emailFromName'"
      placeholder="Add Email Display Name"
      @update="(value) => patchOrganization('emailFromName', value)"
    />

    <p class="label-text">
      Don't send messages on:
      <i
        v-tippy
        class="fas fa-info-circle"
        content="Messages that are scheduled for these days will be sent the previous day"
      />
    </p>
    <toggle
      class="weekend-toggle"
      :active="organization.weekendBumping"
      :loading="patchingOrgField === 'weekendBumping'"
      @toggle-off="() => patchOrganization('weekendBumping', false)"
      @toggle-on="() => patchOrganization('weekendBumping', true)"
    >
      Weekends
    </toggle>

    <flex align="center" gap="0.15rem">
      <i
        :class="[
          {
            'fas fa-caret-down': showQuietDays,
            'fas fa-caret-right': !showQuietDays,
          },
          'pointer',
          'caret',
        ]"
        @click="() => (showQuietDays = !showQuietDays)"
      />
      Selected days
    </flex>
    <quiet-day-setting
      v-show="showQuietDays"
      :organization="organization"
      :quiet-days="quietDays"
      @get-quiet-days="() => $emit('get-quiet-days')"
    />
  </grid-column>
</template>

<script>
import ct from 'countries-and-timezones'
import { sortBy } from 'lodash'

import { api } from '@/api'
import { toast } from '@/toasts'

import gridColumn from '@/components/v2/grid/column.vue'
import gLabel from '@/components/v2/label.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import toggle from '@/components/v2/toggle.vue'
import flex from '@/components/v2/flex.vue'
import dateMixin from '@/mixins/v2/dateMixin'
import quietDaySetting from '@/views/v2/settings/quiet_day_setting.vue'
import editableText from '@/components/v2/editable_text.vue'

export default {
  components: {
    gLabel,
    dropdown,
    dropdownItem,
    gridColumn,
    toggle,
    flex,
    quietDaySetting,
    editableText,
  },
  mixins: [dateMixin],
  props: {
    organization: {
      type: Object,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
    quietDays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      patchingOrgField: null,
      quietDayLoading: false,
      calendarValue: null,
      newQuietDay: null,
      showQuietDays: false,
      deletingQuietDay: null,
    }
  },
  computed: {
    timezoneOptions() {
      const timezones = ct.getAllTimezones()
      const filteredTz = Object.keys(timezones).filter(
        (tz) => timezones[tz].aliasOf === null && tz.includes('/')
      )
      const transformedTz = filteredTz.map((tz) => ({
        name: `GMT${timezones[tz].utcOffsetStr} – ${tz
          .replace(/\//g, ' — ')
          .replace(/_/g, ' ')}`,
        value: tz,
        offset: timezones[tz].utcOffset,
      }))
      return sortBy(transformedTz, (option) => option.offset)
    },
    accountStatus() {
      if (
        this.account.status === 'trial' ||
        this.account.status === 'free' ||
        this.account.status === 'internal'
      ) {
        return this.account.status.toUpperCase()
      }
      if (this.account.status === 'trial_ended') {
        return 'TRIAL ENDED'
      }
      if (this.account.status === 'churned') {
        return 'SUBSCRIPTION ENDED'
      }
      return ''
    },
    accountStatusColor() {
      if (this.account.status === 'trial') {
        return 'pink'
      }
      if (this.account.status === 'trial_ended') {
        return 'light-orange'
      }
      if (this.account.status === 'free') {
        return 'light-green'
      }
      if (this.account.status === 'internal') {
        return 'light-purple'
      }
      if (this.account.status === 'churned') {
        return 'yellow'
      }
      return null
    },
  },
  methods: {
    async patchOrganization(field, value) {
      try {
        this.patchingOrgField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.organization.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingOrgField = null
      }
      this.$emit('get-organization')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';

.org-name {
  margin-left: 0.5rem;
}

.timezone-dropdown {
  margin-bottom: 1em;
}

.weekend-toggle {
  margin-bottom: 0.75em;
}

.caret {
  min-width: 1rem;
  font-size: @medium-icon-font-size;
}
</style>
