<template>
  <table-row>
    <table-cell>
      <editable-text
        :value="aspect.name"
        :loading="patchingAspectField === 'name'"
        :disabled="!aspect.isEditable"
        tooltip="Rename Field"
        placeholder="Add Custom Field"
        @update="(value) => patchAspect(aspect.id, 'name', value)"
      />
    </table-cell>
    <table-cell>
      <dropdown
        name="Aspect Type"
        simple
        default-text="Select Type"
        :disabled="!aspect.isEditable"
        :hide-caret="!aspect.isEditable"
        :initial-value="aspect.type"
        :loading="patchingAspectField === 'type'"
        @update="(value) => patchAspect(aspect.id, 'type', value)"
      >
        <dropdown-item value="date"> Date </dropdown-item>
        <dropdown-item value="text"> Text </dropdown-item>
      </dropdown>
    </table-cell>
    <table-cell>
      <btn
        v-if="aspect.isEditable"
        dismiss
        tooltip="Remove Custom Field"
        compact
        :loading="deletingAspect"
        @click.native="deleteAspect(aspect.id)"
      />
      <div v-else class="grey">Fixed</div>
    </table-cell>
  </table-row>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import btn from '@/components/v2/btn.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableRow from '@/components/v2/table/row.vue'
import editableText from '@/components/v2/editable_text.vue'

export default {
  components: {
    btn,
    dropdown,
    dropdownItem,
    tableCell,
    tableRow,
    editableText,
  },
  props: {
    aspect: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deletingAspect: false,
      patchingAspectField: null,
    }
  },
  methods: {
    async deleteAspect(id) {
      try {
        this.deletingAspect = true
        await api.delete(`${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects/${id}`)
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingAspect = false
      }
      this.$emit('get-aspects')
    },
    async patchAspect(id, field, value) {
      try {
        this.patchingAspectField = field
        await api.patch(`${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects/${id}`, {
          [field]: value,
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingAspectField = null
      }
      this.$emit('get-aspects')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
</style>
